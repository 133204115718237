import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
// TODO: Replace the following with your app's Firebase project configuration
  // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  const firebaseConfig = {
    // ---app: axel-starter
    // apiKey: "AIzaSyCqUrA_iniPZpQShV05_MLPIn9jZxRuGh4",
    // authDomain: "demofirebasesvelte.firebaseapp.com",
    // projectId: "demofirebasesvelte",
    // storageBucket: "demofirebasesvelte.appspot.com",
    // messagingSenderId: "417739618823",
    // appId: "1:417739618823:web:b6dea374a9a176b27d88a2"


    // ---app: bolivia-guide
    apiKey: "AIzaSyDvFVYIeJQPwyXnsSQvRgpQVWyLflrGCT0",
    authDomain: "bolivia-guide-2021.firebaseapp.com",
    projectId: "bolivia-guide-2021",
    storageBucket: "bolivia-guide-2021.appspot.com",
    messagingSenderId: "868767847026",
    appId: "1:868767847026:web:200f7f6004552d710f68eb",
    measurementId: "G-3360C4VZXW"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
// export const fapp = firebase.app();
