<script>
  import MiniHeader from "../../wc/MiniHeader.svelte";

  import { onMount } from "svelte";
  // import firebase from "firebase/app";
  // import * as auth from "firebase/auth";

  import { auth } from "../../firebase.js";

  //   import "firebase/firestore";

  /**
   * Handles the sign in button press.
   */
  function toggleSignIn() {

    if (auth.currentUser) {
      auth.signOut();
    } else {
      let email = document.getElementById("email").value;
      let password = document.getElementById("password").value;
      if (email.length < 4) {
        alert("Please enter an email address.");
        return;
      }
      if (password.length < 4) {
        alert("Please enter a password.");
        return;
      }
      // Sign in with email and pass.
        auth
        .signInWithEmailAndPassword(email, password)
        .catch(function (error) {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            alert("Wrong password.");
          } else {
            alert(errorMessage);
          }
          console.log(error);
          document.getElementById("quickstart-sign-in").disabled = false;
        });
    }
    document.getElementById("quickstart-sign-in").disabled = true;
  }

  /**
   * Handles the sign up button press.
   */
  function handleSignUp() {
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;
    if (email.length < 4) {
      alert("Please enter an email address.");
      return;
    }
    if (password.length < 4) {
      alert("Please enter a password.");
      return;
    }
    // Create user with email and pass.
    auth
      .createUserWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode == "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
  }

  /**
   * Sends an email verification to the user.
   */
  function sendEmailVerification() {
      auth
      .currentUser.sendEmailVerification()
      .then(function () {
        // Email Verification sent!
        alert("Email Verification Sent!");
      });
  }

  function sendPasswordReset() {
    let email = document.getElementById("email").value;
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Password Reset Email Sent!
        alert("Password Reset Email Sent!");
      })
      .catch(function (error) {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode == "auth/invalid-email") {
          alert(errorMessage);
        } else if (errorCode == "auth/user-not-found") {
          alert(errorMessage);
        }
        console.log(error);
      });
  }

  //   >>>
  // ##
  // ##
  // ##
  // ##
  /**
   * initApp handles setting up UI event listeners and registering Firebase auth listeners:
   *  - firebase.auth().onAuthStateChanged: This listener is called when the user is signed in or
   *    out, and that is where we update the UI.
   */
  function initApp() {
    // Listening for auth state changes.
    auth.onAuthStateChanged(function (user) {
      document.getElementById("quickstart-verify-email").disabled = true;
      if (user) {
        // User is signed in.
        let displayName = user.displayName;
        let email = user.email;
        let emailVerified = user.emailVerified;
        let photoURL = user.photoURL;
        let isAnonymous = user.isAnonymous;
        let uid = user.uid;
        let providerData = user.providerData;
        document.getElementById("quickstart-sign-in-status").textContent =
          "Signed in";
        document.getElementById("quickstart-sign-in").textContent = "Sign out";
        document.getElementById(
          "quickstart-account-details"
        ).textContent = JSON.stringify(user, null, "  ");
        if (!emailVerified) {
          document.getElementById("quickstart-verify-email").disabled = false;
        }
      } else {
        // User is signed out.
        document.getElementById("quickstart-sign-in-status").textContent =
          "Signed out";
        // document.getElementById("quickstart-sign-in").textContent = "Sign in";
        document.getElementById("quickstart-account-details").textContent =
          "null";
      }
      document.getElementById("quickstart-sign-in").disabled = false;
    });
    // ##
    // ##
    // ##
    // ##
    // ##
    // ##

    // document
    //   .getElementById("quickstart-sign-in")
    //   .addEventListener("click", toggleSignIn, false);
    document
      .getElementById("quickstart-sign-up")
      .addEventListener("click", handleSignUp, false);
    document
      .getElementById("quickstart-verify-email")
      .addEventListener("click", sendEmailVerification, false);
    document
      .getElementById("quickstart-password-reset")
      .addEventListener("click", sendPasswordReset, false);
  }

  onMount(() => {
    initApp();
  });
</script>

<!-- >>> ROOT -->
<!-- items-center -->
<!-- min-h-screen -->
<div class="
flex  justify-center
sm:px-6 lg:px-8
m-auto
">
  <!-- --- FORM -->
  <form class=" space-y-6 mt-10 mb-15" action="#" method="POST">
    <div class="font-extralight text-3xl">Se connecter</div>
    <input type="hidden" name="remember" value="true" />
    <div class="rounded-md shadow-sm -space-y-px">
      <div>
        <span class=" font-extrabold text-black">Email</span>
        <!-- --- email -->
        <label for="email" class="
          sr-only">Email address</label>

        <input
          id="email"
          name="email"
          type="email"
          autocomplete="email"
          required
          class="appearance-none
            py-3
            px-3
            mt-2
           w-full
           border border-gray-300
           text-xl
           placeholder-gray-400
            text-gray-900
            shadow-sm rounded-md
            focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-purple-500 focus:z-10
            "
          placeholder="Email address..."
        />
      </div>
      <!-- --- separator -->
      <div class="h-5" />
      <span class="text-gray-700 font-extrabold">Password</span>
      <div>
        <!-- --- password -->
        <label for="password" class="sr-only">Password</label>
        <input
          id="password"
          name="password"
          type="password"
          autocomplete="current-password"
          required
          class="
          appearance-none
            py-3
            px-3
            mt-2
           w-full
           border border-gray-300 placeholder-gray-400
            text-gray-900
            shadow-sm
            rounded-md
           focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-purple-500 focus:z-10
            text-xl
           "
          placeholder="Password..."
        />
      </div>
    </div>
    <!-- --- -->
    <!-- <div class="flex items-center justify-between"> -->
    <div class="flex items-center">
      <input
        id="remember_me"
        name="remember_me"
        type="checkbox"
        class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      />
      <label for="remember_me" class="ml-2 block text-sm text-gray-900">
        Remember me
      </label>
    </div>

    <div class="text-sm">
      <a
        href="#login"
        class="font-medium text-indigo-600 hover:text-indigo-500"
      >
        Mot de passe perdu ?
      </a>
    </div>
    <!-- </div> -->

    <div class="flex justify-center">
      <button
        type="submit"
        id="quickstart-sign-in"
        on:click={toggleSignIn}
        class="
        py-3
        px-6
        my-10
        text-sm
        rounded-md text-white
        font-extrabold
        bg-blue-600 ho
        hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Se connecter 22
      </button>
    </div>

    <div class="h-10" />
  </form>
</div>

<!-- Heroicon name: solid/lock-closed -->
<!--
  <span class="absolute left-0 inset-y-0 flex items-center pl-3">
    <svg
class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 20 20"
fill="currentColor"
aria-hidden="true"
>
<path
  fill-rule="evenodd"
  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
  clip-rule="evenodd"
/>
</svg>
</span>-->

<div
  class="bg-yellow-200 demo-layout mdl-layout mdl-js-layout mdl-layout--fixed-header"
>
  <!-- Header section containing title -->
  <header
    class="mdl-layout__header mdl-color-text--white mdl-color--light-blue-700"
  />

  <main class="mdl-layout__content mdl-color--grey-100">
    <div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
      <!-- Container for the demo -->
      <div
        class="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"
      >
        <div class="mdl-card__supporting-text mdl-color-text--grey-600">
          <!-- <button
            disabled
            class="ring-2 ring-indigo-600"
            id="quickstart-sign-in"
            name="signin">Sign In</button
          > -->
          &nbsp;&nbsp;&nbsp;
          <button
            class="ring-2 ring-indigo-600"
            id="quickstart-sign-up"
            name="signup">Sign Up</button
          >
          &nbsp;&nbsp;&nbsp;
          <button
            class="ring-2 ring-indigo-600"
            disabled
            id="quickstart-verify-email"
            name="verify-email">Send Email Verification</button
          >
          &nbsp;&nbsp;&nbsp;
          <button
            class="ring-2 ring-indigo-600"
            id="quickstart-password-reset"
            name="verify-email">Send Password Reset Email</button
          >

          <!-- Container where we'll display the user details -->
          <div class="quickstart-user-details-container">
            Firebase sign-in status: <span id="quickstart-sign-in-status"
              >Unknown</span
            >
            <div>Firebase auth <code>currentUser</code> object value:</div>
            <pre><code id="quickstart-account-details">null</code></pre>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
