<script>
  import Search from "./Search.svelte";
  import Hero1 from "../../../wc/hero/Hero1.svelte";
  import IconLocationMarker from "../../../wc/icon/IconLocationMarker.svelte";
  import {
    filtered,
    keyfilter1,
    keyfilter2,
    keyfilter3,
    globalstore,
    liststore,
    extractL1,
    extractL2,
    extractL3,
    extractL4,
    extractL1Autocomplete,
    extractL2Autocomplete,
  } from "../../../data/stores.js";
  import { onMount } from "svelte";
  import Hero1Black from "../../../wc/hero/Hero1Black.svelte";
  import IconStar from "../../../wc/icon/IconStar.svelte";
  // ---
  function nameUrl(n) {
    return n.replace(" ", "-");
  }
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  // ---
  // --- FIRST, LOAD THE DATA for this list
  // ---
  // ---
  $: if (listlevel === "L1") {
    // city/
    console.log("UI SUMMARY : L1");
    liststore.update((n) => $extractL1);
  } else if (listlevel === "L2") {
    console.log("UI SUMMARY : L2");
    keyfilter1.update((n) => cityCtx);
    liststore.update((n) => $extractL2);
  } else if (listlevel === "L3") {
    console.log("UI SUMMARY : L3");
    keyfilter1.update((n) => cityCtx);
    keyfilter2.update((n) => topicCtx);
    liststore.update((n) => $extractL3);
  } else if (listlevel === "L4") {
    console.log("UI SUMMARY : L4");
    keyfilter1.update((n) => cityCtx);
    keyfilter2.update((n) => topicCtx);
    keyfilter3.update((n) => catCtx);
    liststore.update((n) => $extractL4); // liste de contacts
  }

  // ---
  onMount(() => {
    console.log("onMount : SUMMARY");
  });
  // ---
  // ---
  // ---
  export let listlevel = "";
  export let cityCtx = "";
  export let topicCtx = "";
  export let catCtx = "";
  export let currentCtx = "";
  export let contacts = []; // for level 4
  // ---
  // ---
  // ---
</script>

<!-- --- -->
<!-- ---SEARCH BAR -->
<!-- --- -->
<!-- <div class="flex bg-white sticky top-14 z-10 shadow-lg"> -->
<!-- <Breadcrumb path={[{ name: "CITY", icon: IconLocationMarker }]} /> -->
<!-- <div class="p-2 w-full "> -->
<!-- TODO : remettre SEARCH AUTOCOMPLETE BTN -->
<!-- <Search options={$extractL1Autocomplete} /> -->
<!-- <Search options={$extractL2Autocomplete} /> -->
<!-- </div> -->
<!-- </div> -->

<!-- <MiniHeader /> -->
<!-- --- -->
<!-- --- hero -->
<!-- --- -->
<!-- --- -->
{#if listlevel === "L1"}
  <Hero1
    title4={"(L1) BOLIVIA"}
    title2={currentCtx}
    title1={"www.bolivia-guide.com"}
  />
{:else if listlevel === "L2"}
  <!-- <Breadcrumb path={[{ name: "city", icon: IconLocationMarker }]} /> -->
  <Hero1
    title4={"(L2) All cities of Bolivia"}
    title2={currentCtx}
    title1={"Choose a topic..."}
  />
{:else if listlevel === "L3"}
  <!-- <Breadcrumb path={[{ name: "city", icon: IconLocationMarker }]} />
<Breadcrumb path={[{ name: "city/"+cityCtx, icon: IconLocationMarker }]} /> -->
  <Hero1
    title4={"(L3) All cities > " + cityCtx + " "}
    title2={currentCtx}
    title1={"Choose a category..."}
  />
{:else if listlevel === "L4"}
  <!-- <Breadcrumb path={[{ name: "city", icon: IconLocationMarker }]} />
<Breadcrumb path={[{ name: "city/"+cityCtx, icon: IconLocationMarker }]} />
<Breadcrumb path={[{ name: "city/"+cityCtx+"/topic/"+topicCtx, icon: IconLocationMarker }]} /> -->
  <Hero1
    title4={"(L4) All cities > " + cityCtx + " > " + topicCtx + " "}
    title2={currentCtx}
    title1={"contacts from www.bolivia-guide.com"}
  />
{/if}

<!-- ## -->
<!-- ## -->
<!-- ## content -->
<!-- ## -->
<div
  class="
  mx-2
  grid grid-flow-row
        grid-cols-2
        sm:grid-cols-3
        md:grid-cols-4
        lg:grid-cols-5
        xl:grid-cols-6
        2xl:grid-cols-7
        place-items-center
        gap-4
        bg-white"
>
  {#if listlevel === "L4"}
    <!-- --- -->
    <!-- --- widget que pour contacts -->
    <!-- --- -->
    {#each contacts as contact, i}
      <div class="">{contact.name}</div>
    {/each}
  {:else}
    <!-- --- -->
    <!-- --- -->
    <!-- ---  MEGA COuPURE - widget a sortir -->
    <!-- --- -->
    {#each $liststore as n, i}
      <div class=" hover:shadow-2xl border border-gray-300 rounded-xl">
        <!-- ## -->
        <!-- ## -->
        <!-- ## -->
        <div class="relative ">
          <!-- index -->
          <div
            class="
          rounded-full
          w-6 h-6
          text-white
          bg-black ring-2 ring-black
           absolute top-2   right-2
           text-center
           text-xs

            flex
            place-items-center
            justify-center
           "
          >
            {i}
          </div>

          <!-- --- -->
          <!-- --- img -->
          <!-- --- -->
          <!-- --- -->
          <img
            src={"/i/boguide/city/" + n.img_filename}
            alt=""
            class="rounded-t-lg h-28 object-cover"
          />
        </div>
        <!-- ## -->
        <!-- ## -->
        <!-- ## -->
        <!-- ## -->
        <div class="px-3 py-3">
          <!-- --- -->
          <!-- --- LINK PAGE (routing)-->
          <!-- --- -->
          <!-- --- -->
          {#if listlevel === "L1"}
            <a
              href={"/city/" + nameUrl(n.name)}
              class="text-xl capitalize my-2 ">{n.name}</a
            >
          {:else if listlevel === "L2"}
            <a
              href={"/city/" + nameUrl(cityCtx) + "/topic/" + nameUrl(n.name)}
              class="font-extrabold capitalize ">{n.name}</a
            >
          {:else if listlevel === "L3"}
            <a
              href={"/city/" +
                nameUrl(cityCtx) +
                "/topic/" +
                nameUrl(topicCtx) +
                "/cat/" +
                nameUrl(n.name)}
              class="font-extrabold capitalize ">{n.name}</a
            >
          {/if}

          <!-- --- -->
          <!-- --- TAGS -->
          <!-- --- -->
          <!-- <div class=" text-gray-400 uppercase text-xs ">{'#'+ currentCtx}</div> -->

          <div  class="flex my-2" >
            <IconStar w="w-3" h="h-3" color="orange" />
            <IconStar w="w-3" h="h-3" color="orange" />
            <IconStar w="w-3" h="h-3" color="grey" />
          </div>
            <div class=" text-gray-500  text-xs ">
              { cityCtx + " " + topicCtx + " " + catCtx}
            </div>

        </div>
        <!-- ## -->
        <!-- ## -->
        <!-- ## -->
      </div>
    {/each}

    <!-- --- -->
  {/if}
</div>

<!-- FOOTER -->
<Hero1Black
  title1={"www.axel.app | axel.app@gmail.com"}
  title2={"CONTACT"}
  title4={"Création de votre Sites web à partir de 500 euros TTC"}
/>

<img src="./i/logo/1-logo-playa.jpg" alt="" class="m-auto" />
