
import IconShoppingcard from "../../icon/IconShoppingcard.svelte";
import IconLockopen from "../../icon/IconLockopen.svelte";
import IconContact from "../../icon/IconContact.svelte";
import IconPlusCircle from "../../icon/IconPlusCircle.svelte";

export const MENUGROUP2 = [
  {
    url: "/login-email", // +++
    menuname: "Login Email",
    icon: IconLockopen,
    h: "w-5",
    w: "h-5",
    color: "#ffff00",
  },
  {
    url: "/login-google", // +++
    menuname: "Login Google",
    icon: IconLockopen,
    h: "w-5",
    w: "h-5",
    color: "#ffff00",
  },
  {
    url: "/login",
    menuname: "Login",
    icon: IconContact,
    h: "w-5",
    w: "h-5",
    color: "#006eff",
  },
  // ##
  // ##
  // ##
  {
    url: "/admin", // +++
    menuname: "Admin",
    icon: IconLockopen,
    h: "w-5",
    w: "h-5",
    color: "#ffff00",
  },

  {
    url: "/signup",
    menuname: "S'inscrire",
    icon: IconPlusCircle,
    h: "w-5",
    w: "h-5",
    color: "white",
  },
  {
    url: "/profile",
    menuname: "Profile",
    icon: IconShoppingcard,
    h: "w-5",
    w: "h-5",
    color: "#89d50e",
  },
];
