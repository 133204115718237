<script lang="ts">
  // ---
  import Tailwind from "./Tailwind.svelte";
  // ---
  import Header from "./wc/layout-slidebar/header/Header.svelte";
  import Sidebar from "./wc/layout-slidebar/sidebar/Sidebar.svelte";
  // ---
  import router from "page";
  import { staticRoutes, createDynamicSitemap } from "./routes";
  // ---
  import { globalstore } from "./data/stores.js";
  import db_city  from "./data/3-DB-BOGUIDE-city";
  //  ##
  import firebase from "firebase/app";
  import "firebase/auth";
  import "firebase/firestore";

  import Hero1 from "./wc/hero/Hero1.svelte";
  import IconLocationMarker from "./wc/icon/IconLocationMarker.svelte";
  import BreadcrumbMenuGoogle from "./wc/breadcrumb/BreadcrumbMenuGoogle.svelte";
  import { MENUGROUP1 } from "./wc/layout-slidebar/sidebar/MenuG1";
  import { MENUGROUP2 } from "./wc/layout-slidebar/sidebar/MenuG2";
  import CityPage from "./page/guide/CityPage.svelte";
  // ## DB
  globalstore.update((n) => db_city.arr);
  console.log(
    $globalstore.length,
    " items in data (loaded in > STORE from > 3-DB-BOGUIDE-city.json"
  );

  // ## ROUTING
  // +++ titles for Hera page section
  let props = {};
  let path = "";
  // ---

  // Ax - TODO Im here
  const dynRoutes = createDynamicSitemap($globalstore);
  // routes => se sont les statics routes
  const routes = [...staticRoutes, ...dynRoutes];
  let componentPage = null;
  let params = {};
  let user = true;

  // staticRoutes.forEach((route) => {
  routes.forEach((route) => {
    router(
      route.path,
      (ctx, next) => {
        params = { ...ctx.params };
        next();
      },
      () => {
        // if (route.auth && !user) {
        //   router.redirect("/");
        // } else {
        console.log("updating ROUTE");
        componentPage = route.component;
        props = route.props;
        path = route.path;
      }
    );
  });
  router.start();

  // ## SIDEBAR
  let open = false;
  // let open = true; //debug sidebar
</script>

<Tailwind />

<Sidebar bind:open />
<!-- HEADER -->
<Header bind:sidebar={open} />

<!-- MODALS -->
<BreadcrumbMenuGoogle stickyness="14" path={MENUGROUP1} />
<!-- <BreadcrumbMenu stickyness="28" path={MENUGROUP2} /> -->

<main class="">
  {#if path === "/city"}
    <CityPage />
    <svelte:component this={componentPage} {...props} />
  {:else}
    <svelte:component this={componentPage} {...props} />
  {/if}
</main>
