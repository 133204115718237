
import { writable, derived } from 'svelte/store';


export const globalstore = writable([]);

export const term = writable(''); // for search autocomplete btn (name only arr)

export const keyfilter1 = writable(''); // filters
export const keyfilter2 = writable('');
export const keyfilter3 = writable('');

export const liststore = writable([]); // list store


export const filtered = derived(
	[term, liststore],
	([$term, $liststore]) =>
		$liststore.filter(x => x.name.includes($term.toLocaleLowerCase()))
	// TODO - make this generic

);

// * return an object {}
function extractObject (n) {
	return {
		name: n.name,
		img_filename: n.img_filename
	};
}

export const extractL1 = derived(
	globalstore,
	($globalstore) => $globalstore.map(n => { return extractObject(n) })
);

export const extractL2 = derived(
	[globalstore, keyfilter1],
	([$globalstore, $keyfilter1]) => {
		console.log("keyfilter1 : ", $keyfilter1);
		if ($keyfilter1 === "") {
			console.log("RETURN because no filter : TODO : this mehtod should not be called : PB suscribe");
			return;
		}
		// choix filter 1 : selections les data de la ville
		const selected = $globalstore.filter(
			x => { return x.name === $keyfilter1 });

		//TODO : if multiple selections, so add loop
		const arr = selected[0].arr;
		const debug = arr.map(n => {
			return extractObject(n);
		})
		// console.log(debug);
		return debug;
	}
);

export const extractL3 = derived(
	[globalstore, keyfilter1, keyfilter2],
	([$globalstore, $keyfilter1, $keyfilter2]) => {
		console.log("keyfilter1 : ", $keyfilter1);
		//FIX IT !
		if ($keyfilter1 === '' | $keyfilter2 === '') {
			console.log("RETURN because no filter : TODO : this mehtod should not be called : PB suscribe");
			return;
		}

		const selectedCity = $globalstore.filter(
			x => { return x.name === $keyfilter1 });
		// choix filter 1 : selections les data de la ville
		const arr = selectedCity[0].arr;
		const selectedTopic = arr.filter(
			x => { return x.name === $keyfilter2 });
		//TODO : if multiple selections, so add loop
		const arr1 = selectedTopic[0].arr;
		const debug = arr1.map(n => {
			return extractObject(n);
		})
		// console.log(debug);
		return debug;
	}
);



export const extractL4 = derived(
	[globalstore, keyfilter1, keyfilter2, keyfilter3],
	([$globalstore, $keyfilter1, $keyfilter2, $keyfilter3]) => {
		console.log("keyfilter1 : ", $keyfilter1);
		console.log("keyfilter2 : ", $keyfilter2);
		console.log("keyfilter3 : ", $keyfilter3);
		//FIX IT !
		if ($keyfilter1 === '' | $keyfilter2 === ''| $keyfilter3 === '') {
			console.log("RETURN because no filter : TODO : this mehtod should not be called : PB suscribe");
			return;
		}
		// if ($keyfilter3 === undefined) {
		// 	console.log("undefined ; RETURN because no filter : TODO : this mehtod should not be called : PB suscribe");
		// 	return [];
		// }
		const selectedCity = $globalstore.filter(
			x => { return x.name === $keyfilter1 });
		// choix filter 1 : selections les data de la ville
		const arr = selectedCity[0].arr;
		const selectedTopic = arr.filter(
			x => { return x.name === $keyfilter2 });
		const arr1 = selectedTopic[0].arr;
		const selectedCat = arr1.filter(
			x => { return x.name === $keyfilter3 });

		//TODO : if multiple selections, so add loop
		const arr2 = selectedCat[0].arr;
		const debug = arr2.map(n => {
			// return extractObject(n);
			return n.name; //--> just the name here, not an object, will fail later?
		})
		// console.log(debug);
		return debug;
	}
);










// ---
export const extractL1Autocomplete = derived(
	[globalstore],
	([$globalstore]) =>
		$globalstore.map(x => x.name)
);


export const extractL2Autocomplete = derived(
	[globalstore],
	([$globalstore]) => {

		const v = $globalstore.map(x => {
			x.arr.map((y) => {
				return y.name;
			})
		})

	}
);





	// x.name.includes($term.toLocaleLowerCase())
	// TODO - make this generic
// export const filtered = derived(
// 	[term, globalstore],
// 	([$term, $globalstore]) =>
// 		$globalstore.filter(x => x.name.includes($term.toLocaleLowerCase()))
// 	// TODO - make this generic

// );
