<script>
import { onMount } from "svelte";
import firebase from "firebase/app";
  import "firebase/auth";

  import IconContact from "../../icon/IconContact.svelte";
  import Hamburger from "./Hamburger.svelte";
  import Logo from "./logo/Logo.svelte";
  import LogoImg from "./logo/LogoImg.svelte";

  export let sidebar = false;


  // let username = "se connecter";
  let username = "";

onMount(() => {
  initApp();
});
function initApp() {
  // Listening for auth state changes.
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
      let displayName = user.displayName;
      let email = user.email;
      let emailVerified = user.emailVerified;
      let photoURL = user.photoURL;
      let isAnonymous = user.isAnonymous;
      let uid = user.uid;
      let providerData = user.providerData;
    // ---
      // username = email;
      username = displayName;

    } else {
      // username = "se connecter";
      username = "";
    }
  });
}

</script>
<!-- flex justify-between items-center -->
<!-- +++ HEADER -->
<!-- relative =  mandatory to avoid the shadow of the sidebar <-- -->
<!-- z-10 = pour etre au dessus du slideshow -->
<!-- fixed
  top-0
  left-0
  right-0 -->

<!-- border-b-2
  border-gray-300 -->
  <!-- bg-gray-200 -->
<header class="
pr-3
  bg-white
  text-gray-600
  z-30
  sticky top-0 left-0
  ">
  <nav class="  h-14 grid grid-flow-col grid-cols-3">
    <!-- col 1 --- -->
    <div class="flex col-span-2">
      <Hamburger bind:open={sidebar} />
      <!-- <Logo /> -->
      <!-- <LogoImg /> -->
    </div>

    <!-- col 2 --- SEARCH -->
    <!-- <div class="col-span-6 justify-self-start place-self-center">
      <div class="flex ">
        <div class="h-6 bg-white pl-2">
          <IconSearch />
        </div>
        <input
          value=""
          placeholder="Search..."
          style="font-weight:600"
          class="
        h-6
        w-3/4 rounded-tr-xl rounded-br-xl  p-2

        text-black
        text-sm
        "
        />

      </div>
    </div> -->

    <!-- col 3 -->
    <div
      class="col-span-1
      place-self-end self-center hover:bg-yellow-300
      cursor-pointer"
    >
      <a href="/login"  class="grid grid-flow-col place-items-center" >
        {username}
        <IconContact h={"h-10"} w={"w-10"} color={"white"}/>
      </a>
    </div>
  </nav>
</header>
