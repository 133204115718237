import ParkPage from './page/guide/ParkPage.svelte'
import FirestorePage from './page/FirestorePage.svelte'

import Summary from './page/guide/list/List.svelte'
import Todo from './page/guide/Todo.svelte'
import CityPage from './page/guide/CityPage.svelte'

import HomePage from './page/global/HomePage.svelte'
import SigninPage from './page/global/SigninPage.svelte'
import SigninEmail from './page/global/SigninEmail.svelte'
import SignupPage from './page/global/SignupPage.svelte'
import SignInGooglePopup from './page/global/SignInGooglePopup.svelte'

import ProfilePage from './page/private/ProfilePage.svelte'
import AdminPage from './page/private/AdminPage.svelte'

function _nameUrl (n) {
    return n.replace(' ', '-');
}

export const staticRoutes = [               // export default [

    // ---
// ---
// ---
// --- hack - fast - only url show
// ---
// ---
{
    path: '/firestore',
    component: FirestorePage,
},
{
    path: '/todo',
    component: Todo,
},
// ---
// ---
// ---
// --- end hack
// ---
// ---


    {
        path: '/login-google',
        // TODO--> tmp - redirige client side
        component: SignInGooglePopup,
    },
    {
        path: '/login-Email',
        // TODO--> tmp - redirige client side
        component: SigninEmail,
        // props: {
        // },
    },
    {
        path: '/login',
        // TODO--> tmp - redirige client side
        component: SigninPage,
        // props: {
        // },
    },

    // {
    //     path: '/',
    // },
    //---
    {
        path: '/',
        // TODO--> tmp - redirige client side
        component: HomePage,
    },
    {
        path: '/home',
        component: HomePage,
        // props: {}
    },
    {
        path: '/info',
        // component: InfoPage,
        // props: {}
    },
    {
        path: '/park',
        component: ParkPage,
        props: {}
    },
    //---
    {
        path: '/admin',
        // TODO--> tmp - redirige client side
        component: AdminPage,
        // props: {
        // },
    },

    {
        path: '/signup',
        // TODO--> tmp - redirige client side
        component: SignupPage,
        // props: {
        // },
    },
    {
        path: '/profile',
        // TODO--> tmp - redirige client side
        component: ProfilePage,
        // props: {
        // },
    },

    {
        path: '/city', //---
        component: Summary,
        props: {
            listlevel: "L1",
            currentCtx: "BOLIVIA",
            cityCtx: 'BOLIVIE',
            topicCtx: '',
            catCtx: '',
        },
        // component: CityPage
    },

]; //staticRoutes



// ---
// ---
// ---
// ---
// ---
// ---
export function createDynamicSitemap (arr) {
    // TASK1 : L1 - sitemap for Home (list of cities)
    // L1 - city name
    const arrL1 = arr.map(citySubject => {
        const dto = buildDTO(
            "L2",
            citySubject,
            citySubject.name, '', ''
        );
        return dto;
    });
    // console.log('arr L1', arrL1);

    // TASK2 : L2 - sitemap for cities (list of topics)
    const arrL2 = [];
    arr.map(city => {
        const topicArr = city.arr;
        topicArr.map(topicSubject => {
            const dto = buildDTO(
                "L3",
                topicSubject,
                city.name, topicSubject.name, ''
            );
            arrL2.push(dto);
        });
    })
    // console.log('arr L2', arrL2);

    // TASK3 : L3 - sitemap for topics (list of cats)
    const arrL3 = [];
    arr.map(city => {
        const topicArr = city.arr;
        topicArr.map(topic => {
            const catArr = topic.arr;
            catArr.map(catSubject => {
                const dto = buildDTOContact(
                    "L4",
                    catSubject,
                    city.name, topic.name, catSubject.name,
                    catSubject.arr //--> LE array de catSubject, c est la liste des Kontacts
                );
                arrL3.push(dto);
            })
        })
    });
    // console.log('arr L3', arrL3);
    // DEBUG
    const routes = [...arrL1, ...arrL2, ...arrL3];
    console.log('routes dyn', routes);
    return routes;
}

// decorator factory
function buildDTOContact (LEVEL, subject, cityCtx, topicCtx, catCtx, contacts) {
    let dto = buildDTO(LEVEL, subject, cityCtx, topicCtx, catCtx);
    // --> add dynamically a new component
    dto.props.contacts = contacts;
    return dto;
}

function buildDTO (LEVEL, subject, cityCtx, topicCtx, catCtx) {

    //--> url helper
    const cityCtxPath = (cityCtx !== "") ? '/city/' + _nameUrl(cityCtx) : "";
    const topicCtxPath = (topicCtx !== "") ? '/topic/' + _nameUrl(topicCtx) : "";
    const catCtxPath = (catCtx !== "") ? '/cat/' + _nameUrl(catCtx) : "";
    const CTX = cityCtxPath + topicCtxPath + catCtxPath;
    // console.log("CTX: ", CTX);
    //--> object factory
    return {
        path: CTX, // + '/' + nameUrl(subject.name),
        component: Summary,
        props: { //---
            listlevel: LEVEL,
            // --> avoir une ref sur les parents, pour donner plus d indications sur la page actuelle
            cityCtx: cityCtx,
            topicCtx: topicCtx, //data param : L2 (give parent ctx L1) + current topic (topicCtx)
            catCtx: catCtx,
            currentCtx: subject.name
        },

    };
}













// @@

// ---
// {
//     path: '/blog/:id',
//     component: SingleBlog
// },
// {
//     path: '/private',
//     component: Private,
//     auth: true
// },
// {
//     path: '*',
//     component: PageNotExists
// }

// import SingleBlog from './pages/SingleBlog.svelte'
// import Private from './routes/Private.svelte'
// import PageNotExists from './routes/PageNotExists.svelte'
