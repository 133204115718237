<script>
  import IconClose from "../../icon/IconClose.svelte";
  import IconMenu from "../../icon/IconMenu.svelte";

  export let open = false;
</script>

<!-- class:open -->
<button
  class="
  cursor-pointer
  border-none
   focus:outline-none
   "
  on:click={() => (open = !open)}
>
  {#if open}
    <div
      class="
              hover:bg-yellow-600
              p-2
              m-2
              rounded-full
              "
    >
      <!-- SVG CLOSE --- -->
      <IconClose />
      <!-- <svg
        class="
text-black
w-6
    "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg> -->
    </div>
  {:else}
    <!-- SVG MENU --- -->
    <div class="
hover:bg-yellow-300
p-2
m-2
rounded-full">
      <IconMenu />
    </div>
  {/if}
</button>
