<script>
  export let h = "w-5";
  export let w = "h-5";
  export let color = "";
</script>

<!-- fill={color} -->
<svg
  class="{h} {w}  "
  fill={color}
  stroke="black"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20"
>
  <path
    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
  />
</svg>

<style>
  .test {
    color: #d50ea3;
  }
</style>
