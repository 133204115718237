import IconCamera from "../../icon/IconCamera.svelte";
import IconSpeaker from "../../icon/IconSpeaker.svelte";
import IconGlobe from "../../icon/IconGlobe.svelte";
import IconHome from "../../icon/IconHome.svelte";
import IconMap from "../../icon/IconMap.svelte";

export const MENUGROUP1 = [
  {
    url: "/home", //##
    menuname: "Home",
    icon: IconHome,
    h: "w-7",
    w: "h-7",
    color: "gray",
  },
  {
    url: "/city",
    menuname: "Bolivia",
    icon: IconGlobe,
    h: "w-7",
    w: "h-7",
    color: "gray",
  },
  {
    url: "/park",
    menuname: "Parks",
    icon: IconMap,
    h: "w-7",
    w: "h-7",
    color: "gray",
  },
  {
    url: "/info",
    menuname: "Info",
    icon: IconSpeaker,
    h: "w-7",
    w: "h-7",
    color: "#89d50e",
  },
];

