<script>
  import IconClose from "./icon/IconClose.svelte";
  import IconSearch from "./icon/IconSearch.svelte";

  export let title=""
</script>

<div
  class="
  grid grid-cols-10
  h-14
  p-3
  place-items-center
  shadow-2xl
  bg-white
  text-gray-600"
>
  <!-- SVG SEARCH --- -->
  <!-- justify-self-start -->
  <IconSearch />

  <!-- TITLE --- -->
  <div class="col-span-8" style="font-weight:600">{title}</div>

  <!-- SVG CLOSE --- -->
  <!-- justify-self-end -->
  <IconClose />
</div>
