<script>
  import db_park from "../../data/2-DB-BOGUIDE-park";

  import MiniHeader from "../../wc/MiniHeader.svelte";




  console.log(db_park.arr.length, " items in data");
</script>

<!-- ## PARK -->
<MiniHeader />
<div class="bg-white pb-10">
  <!-- --- hero -->
  <div class="text-center">
    <div class="text-sm pt-10 font-extrabold">BOLIVIE</div>
    <div class="pt-2 pb-4 text-5xl font-extralight">Parks</div>
    <div class="px-10 mb-1" style="font-weight:600 ">
      Most famous, biggest parks in Bolivia.
    </div>
    <div class="text-xs">
      Contact Us for more information about visiting Parks in Bolivia.
    </div>
  </div>
</div>

<div class="grid lg:grid-cols-6 grid-cols-2 bg-gray-100 gap-3 p-1">
  {#each db_park.arr as item, i}
    <div class="">
      <div class="">{item.park_name}</div>
      <img src="i/boguide/park/{item.img_filename}" alt="" />
    </div>
  {/each}
</div>

<!-- "1_park_name": "rague", "2_txt": "Nearest town Sorata", "3_img_filename" -->
