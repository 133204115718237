<script>
  import { MENUGROUP1 } from "./MenuG1";
  import { MENUGROUP2 } from "./MenuG2";

  export let open = false;

  function handleClick() {
    open = false;
  }
</script>

<aside
  class="fixed
  h-full top-14
   bg-white
   border-r
   border-t
   border-gray-300
  z-30
  shadow-2xl"
  class:open
>
  <div class="flex ">
    <div class="">
      <nav class="">
        <!-- SECTION 1 --- -->
        {#each MENUGROUP1 as n, i}
          <!-- TODO : remettre seletion -->
          <!-- class:selected="{selected === item.url}" -->
          <!-- on:click="{() => selected = item.url}" -->
          <a
            on:click={handleClick}
            class="  text-gray-800
            rounded-r-full mr-10


            flex  p-5  gap-5 hover:bg-gray-100  place-items-center"
            style=""
            href={n.url}
          >
              <!-- ## -->
              <svelte:component this={n.icon} w={n.w} h={n.h} color={n.color} />
              <!-- ## -->

              {n.menuname}
          </a>
        {/each}

        <!-- --- SEPARATOR -->
        <div class="border m-5 shadow-2xl" />

        <!-- SECTION 2 --- -->
        <!-- TODO composant redondant -->
        {#each MENUGROUP2 as item, i}
          <div
            class="flex gap-5 mb-5 hover:bg-gray-100 w-80 place-items-center"
          >
            <svelte:component
              this={item.icon}
              w={item.w}
              h={item.h}
              color={item.color}
            />
            <a
              on:click={handleClick}
              class="text-xl my-2"
              style=""
              href={item.url}>{item.menuname}</a
            >
          </div>
        {/each}
      </nav>
    </div>

    <!-- --- img -->
    <!-- <div class="md:block hidden self-center"> -->
      <!-- <img src="./i/logo/1-logo-playa.jpg" alt="" class="m-auto " /> -->
      <!-- <div class="h-20" /> -->
    <!-- </div> -->
  </div>
  <!-- end flex img -->
</aside>

<!-- // const lastSegment = location.href.split("/").pop();
// console.log(lastSegment);
// let selected = "/"+lastSegment;
// on:click={()=>(handleClick();)} -->
<style>
  .selected {
    color: red;
  }
  aside {
    left: -100%;
    transition: left 0.2s ease-in-out;
  }
  .open {
    left: 0;
  }
</style>
