<script>
    export let h = "w-5";
    export let w = "h-5";
    export let color = "";
  </script>



<!-- <svg
class="{h} {w}  "
fill={color}
xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg> -->

  <!-- fill={color} -->
  <svg
  class="{h} {w}  "
fill="none"
xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
  </svg>
