<script>

//     // Display a warning toast, with no title
// toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')

// // Display a success toast, with a title
// toastr.success('Have fun storming the castle!', 'Miracle Max Says')

// // Display an error toast, with a title
// toastr.error('I do not think that word means what you think it means.', 'Inconceivable!')

// // Immediately remove current toasts without using animation
// toastr.remove()

// // Remove current toasts using animation
// toastr.clear()

// // Override global options
// toastr.success('We do have the Kapua suite available.', 'Turtle Bay Resort', {timeOut: 5000})

  import { db } from "../firebase.js";
  // TODO - replace by notifications
  import toastr from "toastr";
  import { onMount } from "svelte";

  let task = {
    name: "",
    description: "",
  };
  let tasks = [];
  let inputElement;
  let editStatus = false;
  let currentId = "";

  onMount(() => {
    // Display an info toast with no title
    toastr.info("onMount");
  });

  // ---
  db.collection("tasks")
    .orderBy("createdAt", "asc")
    .onSnapshot((querySnapshot) => {
      let docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      tasks = [...docs];
      console.log("tasks : " + tasks);
    });
  const addTask = async () => {
    // ##
    try {
      await db
        .collection("tasks")
        .doc()
        .set({ ...task, createdAt: Date.now() });
      toastr.success("Product Added Successfully", "", {
        timeOut: 1000,
        progressBar: true,
        // positionClass: "toast-bottom-right",
        positionClass: "toast-top-right",
      });
      console.log("new Task created");
    } catch (error) {
      console.error(error);
    }
  };
  const deleteTask = async (id) => {
    // &&&
    await db.collection("tasks").doc(id).delete();
    toastr.error("Product Deleted", "", {
        timeOut: 1000,
        progressBar: true,
        // positionClass: "toast-bottom-right",
        positionClass: "toast-top-right",
      });

  };
  const editTask = (currenTask) => {
    currentId = currenTask.id;
    task.name = currenTask.name;
    task.description = currenTask.description;
    editStatus = true;
  };

  const updateTask = async () => {
    // ##
    await db.collection("tasks").doc(currentId).update(task);
    // console.log('should toast');
    toastr.success("Product Updated Successfully", "", {
      timeOut: 1000,
      progressBar: true,
      // positionClass: "toast-bottom-right",
      positionClass: "toast-top-right",
    });
  };
  const handleSubmit = () => {
    if (!task.name) return;
    if (!editStatus) {
      addTask();
    } else {
      updateTask();
      editStatus = false;
      currentId = "";
    }
    task = { name: "", description: "" };
    inputElement.focus();
  };
  const onCancel = () => {
    editStatus = false;
    currentId = "";
    task = { name: "", description: "" };
  };
</script>

<!-- TEMPLATE -->
<div class="">
  <div class="">
    <div class="">
      <form on:submit|preventDefault={handleSubmit} class="">
        <div class="">
          <input
            type="text"
            bind:value={task.name}
            bind:this={inputElement}
            placeholder="Write a new Task"
            class=""
          />
        </div>

        <div>
          <textarea
            bind:value={task.description}
            rows="3"
            placeholder="Write a Task Description"
          />
        </div>

        <div>
          <!-- type="submit ?? -->
          <button class="bg-blue-500 text-white">
            <span>
              {#if !editStatus}
                Save
              {:else}
                Update
              {/if}
            </span>
          </button>
          {#if editStatus}
            <button on:click={onCancel} class="bg-blue-500 text-white"
              >Cancel</button
            >
          {/if}
        </div>
      </form>

      <div class="grid  grid-cols-2 gap-10">
        {#each tasks as task}
          <div class="overflow-hidden p-2 bg-gray-100">
            <div class="">
              <h5>{task.name}</h5>
              <div
                class="bg-blue-500 text-white"
                style=""
                on:click={editTask(task)}
              >
                edit
              </div>
            </div>
            <p>{task.description}</p>
            <div>
              <button
                on:click={deleteTask(task.id)}
                class="bg-blue-500 text-white"
              >
                <span style="">Delete</span>
              </button>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style>
</style>
