<script>
  import { onMount } from "svelte";
  import { auth } from "../../firebase";
  // import "firebase/auth";
  //   import "firebase/firestore";

  /**
   * Handles the sign in button press.
   */
  function toggleSignIn() {
    if (auth.currentUser) {
      auth.signOut();
    } else {
      let email = document.getElementById("email").value;
      let password = document.getElementById("password").value;
      if (email.length < 4) {
        alert("Please enter an email address.");
        return;
      }
      if (password.length < 4) {
        alert("Please enter a password.");
        return;
      }
      // Sign in with email and pass.
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(function (error) {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            alert("Wrong password.");
          } else {
            alert(errorMessage);
          }
          console.log(error);
          document.getElementById("quickstart-sign-in").disabled = false;
        });
    }
    document.getElementById("quickstart-sign-in").disabled = true;
  }

  /**
   * Handles the sign up button press.
   */
  function handleSignUp() {
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;
    if (email.length < 4) {
      alert("Please enter an email address.");
      return;
    }
    if (password.length < 4) {
      alert("Please enter a password.");
      return;
    }
    // Create user with email and pass.
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode == "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
  }

  /**
   * Sends an email verification to the user.
   */
  function sendEmailVerification() {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(function () {
        // Email Verification sent!
        alert("Email Verification Sent!");
      });
  }

  function sendPasswordReset() {
    let email = document.getElementById("email").value;
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        // Password Reset Email Sent!
        alert("Password Reset Email Sent!");
      })
      .catch(function (error) {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode == "auth/invalid-email") {
          alert(errorMessage);
        } else if (errorCode == "auth/user-not-found") {
          alert(errorMessage);
        }
        console.log(error);
      });
  }

  //   >>>
  // ##
  // ##
  // ##
  // ##
  /**
   * initApp handles setting up UI event listeners and registering Firebase auth listeners:
   *  - firebase.auth().onAuthStateChanged: This listener is called when the user is signed in or
   *    out, and that is where we update the UI.
   */
  function initApp() {
    // Listening for auth state changes.
    auth.onAuthStateChanged(function (user) {
      document.getElementById("quickstart-verify-email").disabled = true;
      if (user) {
        // User is signed in.
        let displayName = user.displayName;
        let email = user.email;
        let emailVerified = user.emailVerified;
        let photoURL = user.photoURL;
        let isAnonymous = user.isAnonymous;
        let uid = user.uid;
        let providerData = user.providerData;
        document.getElementById("quickstart-sign-in-status").textContent =
          "Signed in";
        document.getElementById("quickstart-sign-in").textContent = "Sign out";
        document.getElementById(
          "quickstart-account-details"
        ).textContent = JSON.stringify(user, null, "  ");
        if (!emailVerified) {
          document.getElementById("quickstart-verify-email").disabled = false;
        }
      } else {
        // User is signed out.
        document.getElementById("quickstart-sign-in-status").textContent =
          "Signed out";
        document.getElementById("quickstart-sign-in").textContent = "Sign in";
        document.getElementById("quickstart-account-details").textContent =
          "null";
      }
      document.getElementById("quickstart-sign-in").disabled = false;
    });
    // ##
    // ##
    // ##
    // ##
    // ##
    // ##

    document
      .getElementById("quickstart-sign-in")
      .addEventListener("click", toggleSignIn, false);
    document
      .getElementById("quickstart-sign-up")
      .addEventListener("click", handleSignUp, false);
    document
      .getElementById("quickstart-verify-email")
      .addEventListener("click", sendEmailVerification, false);
    document
      .getElementById("quickstart-password-reset")
      .addEventListener("click", sendPasswordReset, false);
  }

  onMount(() => {
    initApp();
  });
</script>

<div
  class="bg-yellow-200 demo-layout mdl-layout mdl-js-layout mdl-layout--fixed-header"
>
  <!-- Header section containing title -->
  <header
    class="mdl-layout__header mdl-color-text--white mdl-color--light-blue-700"
  >
    <div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
      <div
        class="mdl-layout__header-row mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--8-col-desktop"
      >
        <a href="/"><h3>Firebase Authentication</h3></a>
      </div>
    </div>
  </header>

  <main class="mdl-layout__content mdl-color--grey-100">
    <div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
      <!-- Container for the demo -->
      <div
        class="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"
      >
        <div
          class="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white"
        >
          <h2 class="mdl-card__title-text">
            Firebase Email &amp; Password Authentication
          </h2>
        </div>
        <div class="mdl-card__supporting-text mdl-color-text--grey-600">
          <p>
            Enter an email and password below and either sign in to an existing
            account or sign up
          </p>

          <input
            class="mdl-textfield__input"
            style="display:inline;width:auto;"
            type="text"
            id="email"
            name="email"
            placeholder="Email"
          />
          &nbsp;&nbsp;&nbsp;
          <input
            class="mdl-textfield__input"
            style="display:inline;width:auto;"
            type="password"
            id="password"
            name="password"
            placeholder="Password"
          />
          <br /><br />
          <button
            disabled
            class="ring-2 ring-indigo-600"
            id="quickstart-sign-in"
            name="signin">Sign In</button
          >
          &nbsp;&nbsp;&nbsp;
          <button
            class="ring-2 ring-indigo-600"
            id="quickstart-sign-up"
            name="signup">Sign Up</button
          >
          &nbsp;&nbsp;&nbsp;
          <button
            class="ring-2 ring-indigo-600"
            disabled
            id="quickstart-verify-email"
            name="verify-email">Send Email Verification</button
          >
          &nbsp;&nbsp;&nbsp;
          <button
            class="ring-2 ring-indigo-600"
            id="quickstart-password-reset"
            name="verify-email">Send Password Reset Email</button
          >

          <!-- Container where we'll display the user details -->
          <div class="quickstart-user-details-container">
            Firebase sign-in status: <span id="quickstart-sign-in-status"
              >Unknown</span
            >
            <div>Firebase auth <code>currentUser</code> object value:</div>
            <pre><code id="quickstart-account-details">null</code></pre>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
