<script>
    export let path = []; //{url, name, icon}, {}...
    export let stickyness = "14";

    // ---
    function nameUrl(n) {
      return n.replace(" ", "-");
    }

  </script>
  <!-- shadow-md rounded-lg -->
  <!-- flex sticky top-{stickyness} -->
  <div class="
  sticky top-{stickyness}
  h-14
  shadow-lg
  bg-white
  z-10

    grid grid-flow-col justify-center gap-1

      ">
    <!-- BREADCRUMB -->
    {#each path as n, i}
      <!-- <IconRightArrow /> -->
      <a
        href="{nameUrl(n.url)}"
        class="bg-white
             px-2
             my-2
          text-sm
          text-black
hover:font-extrabold
          hover:text-blue-600
          flex items-center
          "
      >
        <div class="inline mr-1">
          <svelte:component this={n.icon} color={n.color} w={n.w} h={n.h}/>
        </div>
        {n.menuname}
      </a>
    {/each}
  </div>


  <style>
  </style>
