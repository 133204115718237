<script>
  export let title1 = "";
  export let title2 = "";
  export let title4 = "";
  export let color = "black";
  // export let title3 = "";
</script>

<!-- --- hero -->
<div class="{color}  w-full text-black pb-5">
  <div class="text-center ">
    <div class="pt-10 text-xs mb-2  font-extrabold">
      {title4}
    </div>
    <div class=" text-6xl font-extralight capitalize mb-2 text-red-500">{title2}</div>
    <div class="text-sm  text-blue-500">{title1}</div>

    <!-- <div class="px-10 mb-1" style="font-weight:600 ">
      {title3}
    </div> -->
  </div>
</div>

<style></style>
