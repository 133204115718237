<script>
  import { onMount } from "svelte";
  import firebase from "firebase/app";
  import "firebase/auth";

  /**
   * Function called when clicking the Login/Logout button.
   */
  function toggleSignIn() {
    if (!firebase.auth().currentUser) {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          let token = result.credential.accessToken;
          // The signed-in user info.
          let user = result.user;
          document.getElementById("quickstart-oauthtoken").textContent = token;
        })
        .catch(function (error) {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          // The email of the user's account used.
          let email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          let credential = error.credential;
          if (errorCode === "auth/account-exists-with-different-credential") {
            alert(
              "You have already signed up with a different auth provider for that email."
            );
            // If you are using multiple auth providers on your app you should handle linking
            // the user's accounts here.
          } else {
            console.error(error);
          }
        });
    } else {
      firebase.auth().signOut();
    }
    document.getElementById("quickstart-sign-in").disabled = true;
  }

  /**
   * initApp handles setting up UI event listeners and registering Firebase auth listeners:
   *  - firebase.auth().onAuthStateChanged: This listener is called when the user is signed in or
   *    out, and that is where we update the UI.
   */
  function initApp() {
    // Listening for auth state changes.
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        let displayName = user.displayName;
        let email = user.email;
        let emailVerified = user.emailVerified;
        let photoURL = user.photoURL;
        let isAnonymous = user.isAnonymous;
        let uid = user.uid;
        let providerData = user.providerData;
        document.getElementById("quickstart-sign-in-status").textContent =
          "Signed in";
        document.getElementById("quickstart-sign-in").textContent = "Sign out";
        document.getElementById(
          "quickstart-account-details"
        ).textContent = JSON.stringify(user, null, "  ");
      } else {
        // User is signed out.
        document.getElementById("quickstart-sign-in-status").textContent =
          "Signed out";
        document.getElementById("quickstart-sign-in").textContent =
          "Sign in with Google";
        document.getElementById("quickstart-account-details").textContent =
          "null";
        document.getElementById("quickstart-oauthtoken").textContent = "null";
      }
      document.getElementById("quickstart-sign-in").disabled = false;
    });

    document
      .getElementById("quickstart-sign-in")
      .addEventListener("click", toggleSignIn, false);
  }

  onMount(() => {
    initApp();
  });
</script>

<div class="
bg-yellow-300
demo-layout mdl-layout mdl-js-layout mdl-layout--fixed-header">
  <!-- Header section containing title -->
  <header
    class="mdl-layout__header mdl-color-text--white mdl-color--light-blue-700"
  >
    <div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
      <div
        class="mdl-layout__header-row mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--8-col-desktop"
      >
        <a href="/"><h3>Firebase Authentication</h3></a>
      </div>
    </div>
  </header>

  <main class="mdl-layout__content mdl-color--grey-100">
    <div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
      <!-- Container for the demo -->
      <div
        class="mdl-card mdl-shadow--2dp mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop"
      >
        <div
          class="mdl-card__title mdl-color--light-blue-600 mdl-color-text--white"
        >
          <h2 class="mdl-card__title-text">Google Authentication with Popup</h2>
        </div>
        <div class="mdl-card__supporting-text mdl-color-text--grey-600">
          <p>Sign in with your Google account below.</p>

          <!-- Button that handles sign-in/sign-out -->
          <button
            disabled
            class="mdl-button mdl-js-button mdl-button--raised"
            id="quickstart-sign-in">Sign in with Google</button
          >

          <!-- Container where we'll display the user details -->
          <div class="quickstart-user-details-container">
            Firebase sign-in status: <span id="quickstart-sign-in-status"
              >Unknown</span
            >
            <div>Firebase auth <code>currentUser</code> object value:</div>
            <pre><code id="quickstart-account-details">null</code></pre>
            <div>Google OAuth Access Token:</div>
            <pre><code id="quickstart-oauthtoken">null</code></pre>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
