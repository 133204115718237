<script>
  export let title1 = "";
  export let title2 = "";
  export let title4 = "";
  export let color = "bg-white";
  // export let title3 = "";
</script>

<!-- --- hero -->
<div class="{color} pb-5 w-full ">
  <div class="text-center ">
    <div class="pt-10 text-xs mb-2 ">
      {title4}
    </div>
    <div class=" text-5xl font-extralight capitalize mb-2">{title2}</div>
    <div class="text-sm  font-extrabold">{title1}</div>

    <!-- <div class="px-10 mb-1" style="font-weight:600 ">
      {title3}
    </div> -->
  </div>
</div>

<style></style>
