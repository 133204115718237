<script>
  export let path = [];
  // export let stickyness = 14;

  // ---
  function nameUrl(n) {
    return n.replace(" ", "-");
  }
</script>
<!-- shadow-md rounded-lg -->
<!-- flex sticky top-{stickyness} -->
<div class="
    flex place-items-start items-center gap-3
    p-2
    ">
  <!-- BREADCRUMB -->
  {#each path as item, i}
    <!-- <IconRightArrow /> -->
    <a
      href="/{nameUrl(item.name)}"
      class="bg-white
           px-2 py-1
           ring-1 ring-gray-300
           rounded-md shadow-inner
        text-sm
        text-black
        focus:ring-4
        focus:ring-red-500
        hover:bg-yellow-300
        flex
        "
    >
      <div class="inline mr-1">
        <svelte:component this={item.icon} />
      </div>
      {item.name}
    </a>
  {/each}
</div>

<!-- import IconBook from "../icon/IconBook.svelte";
  import IconHome from "../icon/IconHome.svelte";
  import IconRightArrow from "../icon/IconRightArrow.svelte"; -->

<style>
  /* ul.breadcrumb li { */
  /* display: inline;
    font-size: 18px; */
  /* } */
</style>
